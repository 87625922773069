<template>
  <div class="map-cont">
    <el-amap vid="amapDemo"  
      :center="center" 
 
      :zoom="zoom" 
      :events="events">
    </el-amap>
    <div class="footer" @click="goAmap">
      <div class="f-left">
        <div class="name">{{info.name}}</div>
        <div class="address nowrap2">{{info.address}}</div>
      </div>
      <div class="f-right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    const vm = this;
    return {
      center: [this.$route.query.lng -0, this.$route.query.lat -0],
      zoom: 15,
      events: {
        init(o) {
          let marker = new AMap.Marker({
            position: vm.center
          });

          marker.setMap(o);
        }
      },
      info: this.$route.query
    }
  },
  methods: {
    goAmap () {
      window.location.href = 'https://uri.amap.com/marker?position='+this.center.join(',') +'&name='+ this.info.name
    }
  }
}
</script>

<style lang="scss" scoped type="text/css">
.map-cont{
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding-bottom: 160px;
}
.footer{
  padding: 20px 30px;
  box-sizing: border-box;
  width: 100%;
  height: 160px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  align-items:center;
  .f-left{
    .name{
      margin-bottom: 12px;
    }
    .address{
      font-family: PingFangSC-Regular;
      font-size: 26px;
      color: #999999;
      letter-spacing: 0.42px;
      line-height: 32px;
    }
  }
  .f-right{

  }
}
</style>